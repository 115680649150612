/*------------------------------------------------------------------------------*/
/*	           _________._____ /\__.____. 										*/
/*	           \    ___/     //   /_   / \_										*/
/*	         __ \\__   /   _/ \____/__,   / 									*/
/*	   _____/__________\___\____ / /_    / 										*/
/*	   \     _/__._______\    __/_.__\  /_.______._______._______._______ 		*/
/*	   /    _    \  __   //       \___\/ \_   _  \_   _  \_  __  \_   _  \_		*/
/*	  /    _/    /  \   _/    /  _/   _/  /   /   /   /   /  \    /   /   /		*/
/*	  \____\____/\\_____\____/___\  ____//___/___/___/___/\\_____/___/___/		*/
/*	                              \/ 											*/
/*------------------------------------------------------------------------------*/

*:focus { outline: none; }

*, *:before, *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.wrap, .wrap-p {
	margin: 0 auto;
	max-width: 1240px;
}
.wrap-full {
	margin: 0 auto;
	width: 100%;
}
.wrap-p {
	padding: 0 1.6rem;
}

.nopad {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

/* mixins										//		*/
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
 	border-radius: $radius;
 	background-clip: padding-box;
}

@mixin opacity($opacity) {
 	opacity: $opacity;
 	$opacity-ie: $opacity * 100;
 	filter: alpha(opacity=$opacity-ie);
}

@mixin scale($factor) {
    -webkit-transform: scale($factor);
    -moz-transform:     scale($factor);
    -ms-transform:      scale($factor);
    transform:          scale($factor);
}

@mixin transform($transforms) {
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
    transform: $transforms;
}
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

%clearfix { 
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after { 
   clear: both;
  }
}

/* colors										//		*/
$darkblue: #15151e;
$lightmag: #61557e;
$orange: #f16522;
$white: #ffffff;
$black: #000000;

@font-face {
    font-family: 'AvertaStd-Bold';
    src: url('/fonts/AvertaStd-Bold.eot');
    src: url('/fonts/AvertaStd-Bold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/AvertaStd-Bold.woff2') format('woff2'),
         url('/fonts/AvertaStd-Bold.woff') format('woff'),
         url('/fonts/AvertaStd-Bold.ttf')  format('truetype'),
         url('/fonts/AvertaStd-Bold.svg#AvertaStd-Bold') format('svg');
}

html { 
	font-size: 62.5%;
} 

body {
	font-size: 1.4rem;
	background: $darkblue url('/img/stars.png') repeat;

	&.gallery {
		background: $white;
		margin: 0; 												padding: 0;
	}
}
header {
	margin: 4.2rem 0;

	p.head {
		margin: 0;												padding: 0;
		text-align: center;
		padding: 0;
		font-family: 'AvertaStd-Bold', sans-serif;
		font-weight: bold;
		font-size: 6.4rem;										line-height: 1;
		font-style: normal;
		text-transform: uppercase;
		color: $white;
	}

	p.sub {
		margin: 0;												padding: 0;
		color: $white;
		text-align: center;
		font-family: 'AvertaStd-Bold', sans-serif;
		font-weight: 600;
		font-size: 3.2rem;										line-height: 1;
		color: darken($white, 7.5%);
		text-transform: uppercase;
	}

	@media (max-width: 768px) {
		margin: 2.4rem 0;

		p.head {
			font-size: 4.6rem;
		}
		p.sub {
			font-size: 2.4rem;
		}
	}
}
main {
	margin: 0;													padding: 0;

	#portfolio {
		overflow: hidden;
		margin: 0;
		padding: 0 0 6rem;
		
		@media (max-width: 768px) {
			margin: 0;
		}

		#port-controls {
			margin: 0;												padding: 0 3.6rem 0 0;

			span {
				display: none;
				margin: 1rem 0 0.5em;										padding: 0 0 0 1rem;

				color: darken(#fff,10%);									font-weight: 700;
				font-size: 1rem;											text-transform: uppercase;
			}

			ul {
				margin: 0;													padding: 0;							
				text-align: left;
				background: transparent;
				list-style: none;
				width: 100%;

				li {
					display: block;					
					margin: 0;														padding: 1rem 0 1rem 3rem;
					font-family: "AvertaStd-Bold", sans-serif;
					font-weight: bold;												font-size: 2.2rem;
					cursor: pointer;
					color: darken(#fff,10%);										letter-spacing: 1px;
					text-transform: uppercase;										text-align: left;
					overflow: hidden;
					background: transparent;

					border-right: 0.3rem solid transparent;
					border-bottom: 0 none;
					
					-webkit-transition: all 0.5s, -webkit-transform 0.5s;
					transition: all 0.5s, transform 0.5s;

					&.mixitup-control-active {
						background: darken($darkblue, 5%);
						border-right: 3px solid $orange;

						&:hover, &:focus {
							color: darken(#fff, 10%);
						}
					}

					&:hover, &:focus {
						color: darken(#fff, 20%);
					}

				} /* li 				*/

				@media (max-width: 992px) {

					margin: 0 0 2.4rem;

					li {
						display: inline-block;
						padding: 1rem 0;
						font-size: 1.8rem;
						text-align: center;
						background: $darkblue;

						border-bottom: 0.3rem solid lighten($darkblue,25%);
						border-right: 0 none;

						&.mixitup-control-active {
							border-bottom: 0.3rem solid $orange;;
							border-right: 0 none;
						}
					}
				}
			}


			@media (max-width: 992px) {
				display: inline-block;
				margin: 0rem 0 0;											padding: 0;
				width: 100%;

			}

		}
		.stuck { 
			

			@media (max-width: 992px) {
				position: fixed; 
				top: 0; 												left: 0;
				z-index: 999;
			}
		}

		#port-items {
			margin: 0;

			@media (max-width: 992px) {
				padding: 0 2.4rem;
			}

			ul {
				margin: 0;												padding: 0;
				list-style-type: none;

				li {
					margin: 0 0 1.6rem;

						figure {
						margin: 0 0.8rem 0;
						position: relative;
						cursor: pointer;
						overflow: hidden;

						&:focus {
							outline: none;
						}
						&:active {
							outline: none;
						}

						img {
							display: block;
							position: relative;
							overflow: hidden;
							outline: none;
							border: 0 none;
						}

						figcaption {
							position: absolute;
						    width: 100%;										height: 27%;
						    top: auto;											bottom: 0;
						    @include opacity(0);
						    padding: 0;

						    transform: translateY(100%);
						    transition: transform 0.4s, opacity 0.6s;

						    background: #12101a;


					    	.meta {
					    		margin: 0;											padding: 1.2rem;
					    		list-style-type: none;
					    		text-align: left;	
							  	width: 100%;

					    		.item-title {
									margin: 0;											padding: 0;
									font-size: 2rem;
								    color: #fff;		 
								    text-transform: uppercase;
								    font-weight: bold;									line-height: 1;
								    font-family: 'AvertaStd-Bold', sans-serif;

								    @media (max-width: 768px) {
								    	font-size: 2.2rem;
								    }
								    @media (min-width: 768px) and (max-width: 992px) {
								    	font-size: 3rem;
								    }	
								}
					    	}

						} /* figcaption 			*/

						&:hover {

							figcaption {
								@include opacity(0.85);
							    transform: translateY(0px);
							    transition: transform 0.5s, opacity 1s;
							}
						}
					}
				}
			}
			
		}
	} 
	/* end portfolio 			//	*/

	#spaceship {
		margin: 4.8rem 0 0;								padding: 0;
		overflow: hidden;
		text-align: center;


		img {
			max-width: 319px;
			&:hover {
				@include rotate(-8);
			}

			transition: all 2s;
		}

		@media (max-width: 768px) {
			padding: 0 4.8rem;
		}
	}
	footer {
		margin: 0;
			width: 100%;										height: 216px;
			max-height: 216px;
			background: url('/img/mountains.svg') no-repeat center center;
			background-size: cover;

			@media (max-width: 768px) {
				height: 120px;
			}
		}
	}
	
	.contact {
		margin: 0;														padding: 1.2rem 0 3.6rem;
		background: #0d0d11;
 
		p {
			margin: 0;														padding: 1.2rem 0 0;
			font-family: 'AvertaStd-Bold', sans-serif;
			font-weight: 400;
			font-size: 1.6rem;												line-height: 1;
			text-align: center;
			color: $white;

			a {
				text-decoration: none;									color: $orange;
			}
		}

	}

/* fancybox 					//	*/

.fancybox-nav span {
	margin: 0;										padding: 0;
	position: absolute;
	top: 50%;
	width: 42px;									height: 70px;
	margin-top: -21px;
	cursor: pointer;
	z-index: 8040;
	visibility: hidden;
}

.fancybox-prev {
	span {
		left: -21px;
		background: url('/img/left-arrow.png') no-repeat;

		transition: all 0.3s;
		&:hover {
			top: 48%;
		}
	}

}

.fancybox-next {
	span {
		right: -21px;
		background: url('/img/right-arrow.png') no-repeat;

		transition: all 0.3s;
		&:hover {
			top: 48%;
		}
	}
}

.fancybox-title {
	position: relative;
	visibility: hidden;
	font-family: "AvertaStd-Bold", sans-serif;
	color: $white;
	 font-weight: bold;
    font-style: normal;
	font-size: 4.4rem;									line-height: 4.4rem;
	text-transform: uppercase;
	text-shadow: none;
	z-index: 8050;
	text-align: center;
}

.fancybox-opened .fancybox-title {
	visibility: visible;
}


.fancybox-title-outside-wrap {
	position: relative;
	margin: 1.6rem 0 0;
}

#layerslider {
	overflow: hidden;

	.url {
		margin: 0; 	
		background: $darkblue;
		@include border-radius(1.2rem);

		span {
			padding: 0.8rem 1.6rem;
			display: block;
			color: $white;
			font-family: "AvertaStd-Bold", sans-serif;
			font-weight: 600;
			font-size: 1.8rem;										line-height: 1;
		}

		a {
			color: $white;
			text-decoration: none;
		}

		&:hover {

		}

		&:before {

		}

		@media (max-width: 768px) {
			padding: 0.6rem 1.4rem;
			span {
				font-size: 1.2rem;
			}
		}

	}
}


/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links for images, or javascript/internal links
     */

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

.glitch {
  color: white;
  font-size: 100px;
  position: relative;
  width: 400px;
  margin: 0 auto;
}
@keyframes noise-anim {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      $top: random(100);
      $bottom: random(101 - $top);
      clip-path: inset(#{$top}% 0 #{$bottom}%  0);
    }
  }
}
.glitch::after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 red;
  top: 0;
  color: white;
  background: transparent;
  overflow: hidden;
  animation: noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2 {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      $top: random(100);
      $bottom: random(101 - $top);
      clip-path: inset(#{$top}% 0 #{$bottom}%  0);
    }
  }
}
.glitch::before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 blue;
  top: 0;
  color: white;
  background: transparent;
  overflow: hidden;
  animation: noise-anim-2 15s infinite linear alternate-reverse;
}